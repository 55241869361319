import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const shortid = require('shortid');

const galleryImages = [
    {
        image: <StaticImage
        src="../images/shoot/DSCF1263.jpg"
        quality={60}
        formats={["auto", "webp", "avif"]}
        alt="Flowers"
    />,
        id: shortid.generate()

    },
    {
        image: <StaticImage
        src="../images/shoot/DSCF1290.jpg"
        quality={60}
        formats={["auto", "webp", "avif"]}
        alt="Piano"
    />,
        id: shortid.generate()

    },
    {
        image: <StaticImage
        src="../images/shoot/DSCF1303.jpg"
        quality={60}
        formats={["auto", "webp", "avif"]}
        alt="Shoes"
    />,
        id: shortid.generate()

    },
    {
        image: <StaticImage
        src="../images/shoot/DSCF1326.jpg"
        quality={60}
        formats={["auto", "webp", "avif"]}
        alt="Tree"
    />,
        id: shortid.generate()

    },
    {
        image: <StaticImage
        src="../images/shoot/DSCF1414---edit.jpg"
        quality={60}
        formats={["auto", "webp", "avif"]}
        alt="Milo"
    />,
        id: shortid.generate()

    },
    {
        image: <StaticImage
        src="../images/shoot/DSCF1260.jpg"
        quality={60}
        formats={["auto", "webp", "avif"]}
        alt="Piano"
    />,
        id: shortid.generate()

    }
]

export default galleryImages