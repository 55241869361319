import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import CoverMedia from "../components/CoverMedia"
// import useScrollDirection from "../utils/useScrollDirection"
// import LinerNotes from "../components/LinerNotes"
// import Container from "../components/Container"
import Charts from "../components/Charts"
import Section from "../components/Section"
import Button from "../components/Button"
import Press from "../components/Press"
import Introduction from "../components/Introduction"
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import { PageTitle } from "../components/Titles"
import PicGrid from "../components/PicGrid"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomepageQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
            partActive
          }
        }
      }

      wpPage(slug: {eq: "home"}) {
        title
        slug
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    
  `)

  // const scrollDir = useScrollDirection();


  
  return (
    <Layout>
      <Seo title="Home" />

      <CoverMedia mediaData={data.wpPage.featuredImage} className={`lg:h-[120vh] md:min-h-[720px] h-screen md:flex md:flex-col md:justify-end md:items-start border-8 border-t-0 border-contrast`} innerClassName={`md:!sticky bottom-0 pb-10 left-10 md:inline-block p-2 hidden`}>

      <div className={`flex flex-col lg:grid lg:grid-cols-2 lg:gap-x-16 lg:gap-y-0.5 items-start justify-start space-y-2 lg:space-y-0 text-contrast font-normal tracking-[0.025rem] text-2xl uppercase`}>
        {data.site.siteMetadata.menuLinks.slice(1).map((link, index) => (
          <React.Fragment key={index}>

          { link.link.includes('http') ? (
            <a className={`hover:underline decoration-[1.5px] underline-offset-4`} href={link.link} title={link.name} target={`_blank`} rel={`noopener noreferrer`}>{link.name}</a>
          ) : link.link.includes('#') ? (
            <AnchorLink className={`hover:underline decoration-[1.5px] underline-offset-4`} to={link.link} title={link.name}>{link.name}</AnchorLink>
          ) : (
            <Link className={`hover:underline decoration-[1.5px] underline-offset-4`} to={link.link} title={link.name} partiallyActive={link.partActive}>{link.name}</Link>
          )

          }

          </React.Fragment>
        ))}
        </div>
      </CoverMedia>

      <Section containerSize={`xs`}>
          <Introduction />
      </Section>

      <Section className={`hidden`} containerSize={`xs`}>
       <div className={`font-brand text-2xl md:text-4xl text-center bg-prose text-contrast p-3 md:p-5 lg:p-10 xl:p-14 shadow-lg`}
        style={{backgroundImage: `url("https://www.transparenttextures.com/patterns/beige-paper.png")`, backgroundSize: '150px'}}
       >
       <p className={`mb-16`}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vel ante nunc. Curabitur sollicitudin facilisis dolor, et fermentum mauris dignissim vitae. Proin sapien eros, venenatis dictum purus at, suscipit laoreet odio. Cras sit amet ultricies diam. Donec non commodo elit. Donec ut neque at diam egestas suscipit. 
        </p>
        <p>
        Aenean eleifend mollis efficitur. Fusce placerat, lectus sit amet ornare ullamcorper, est massa posuere ipsum, id dignissim orci mauris non nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aliquam placerat dolor sed urna eleifend, vitae euismod eros commodo.
        </p>
        <p>
          - MILO
        </p>
       </div>
      </Section>

      <Section 
      style={{textAlign: 'center'}}
      containerSize={`xs`} pageTitle={`Wishful Thinking`} pageTitleSize={`text-7xl sm:text-8xl md:text-9xl`}>
        
      <p>
      The debut album from <strong>Milo Walker Johnson</strong>.
      </p>
      <StaticImage
                src="../images/music/WISHFUL_TINKING_RGB_WEB_.jpg"
                quality={85}
                formats={["auto", "webp", "avif"]}
                alt="Wishful Thinking"
                className={`mt-5 sm:my-12 sm:mt-10 item-shadow item-shadow-static`}
              />

          <div className={`mt-8`}>
          <Button to={`/music/wishful-thinking`}>Learn More</Button>
          <Button to={`/music`} variation={`outline`}>All Music</Button>
          </div>
      </Section>
        
       {/* <Section containerSize={`lg`} className={`hidden`}>
         <div className={`flex md:flex-row md:items-center md:-m-5`}>
            
            <div className={`p-5 md:flex-[40%] md:max-w-[40%] relative  z-10`}>
            <h1 
            className={`md:text-9xl italic uppercase md:-mr-20 lg:-mr-32 md:whitespace-nowrap md:text-right`}>Wishful<br/>Thinking</h1>
            
            <p>Debut album out now.</p>
            <Button to={`/`}>Stream</Button>
            <Button to={`/`} variation="secondary">Vinyl</Button>
            <Button to={`/music/wishful-thinking`} variation="outline">Learn More</Button>
            </div>
            <div className={`p-5 z-0 md:flex-[60%] md:max-w-[60%]`}>
            <StaticImage
                src="../images/music/WISHFUL_TINKING_RGB_WEB_.jpg"
                quality={85}
                formats={["auto", "webp", "avif"]}
                alt="Wishful Thinking"
              />
            </div>
         </div>

      </Section>  */}

      <Section containerSize={`lg`} id={`charts`}>
      <Charts sectionTitle="Charts" sliderClassName={`md:-mr-[2.5rem] -mr-2 homepage-chart-slider overflow-hidden pb-5`} />
      </Section>

      <Section sectionTitle={`Press`} id={`press`} containerSize={`lg`} className={`-mt-5`}>
        <Press />
      </Section>

      <Section containerSize={`lg`}>
          <PicGrid number={6} contained={true} />
      </Section>
      
  
      {/* <p>{scrollDir}</p> */}
 
    </Layout>
  )
}

export default IndexPage
