import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { pressFigure, pressDetails, overlayBlur } from "../styles/press.module.scss"

const Press = (props) => {
    const data = useStaticQuery(graphql`
    query PressQuery {
        allWpPressItem(
            sort: {fields: pressSettings___pressPublication, order: ASC}
            filter: {status: {eq: "publish"}}
          ) {
            edges {
              node {
                slug
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                      publicURL
                    }
                    altText
                  }
                }
                id
                pressSettings {
                  pressAuthor
                  pressDate
                  pressPublication
                  pressUrl
                  pressTitle
                }
              }
            }
          }

}
    `)

    const { className } = props;
    let pressClassName = `overflow-hidden`;
    if ( className ) pressClassName = [`overflow-hidden`, className].join(" ");

    return (
        <div className={pressClassName}>
            <div className={`flex flex-row flex-wrap items-start my-0 -mx-5 md:-m-5 xl:grid xl:grid-cols-3 xl:grid-rows-2 xl:m-0 xl:gap-x-20 xl:gap-y-5`}>

                {data.allWpPressItem.edges.slice(0,3).map((press, index) => {

                const imageData = getImage(press.node.featuredImage?.node?.localFile)

                const pressItemTitle = press.node.pressSettings.pressTitle || press.node.title;


                const pkArray = [
                    press.node.pressSettings.pressPublication,
                    press.node.pressSettings.pressDate
                ]

                const pkFlattened = [].concat(...pkArray);

                const pkMetaInfo = pkFlattened.filter(x => x !== null);

                    return (

                        <div key={press.node.id} className={`w-full md:max-w-1/2 lg:max-w-1/3 md:flex-1/2 lg:flex-1/3 p-5  ${index === 0 ? 'xl:max-w-full xl:col-span-2 xl:row-span-2 xl:p-0' : 'xl:p-0 xl:max-w-full xl:col-span-1 xl:row-span-1'}`}>
                            {
                                press.node.featuredImage &&

                                <figure className={`${pressFigure} item-shadow`}>
                                    <a href={press.node.pressSettings.pressUrl} target={`_blank`} rel={`noopener noreferrer`} title={pressItemTitle}
                                    
                                    >
                                    <GatsbyImage 
                                    image={getImage(imageData)} alt={press.node.featuredImage.altText || press.node.title} 
                                    />
                                    <div className={overlayBlur} style={{backgroundImage: `url( ${press.node.featuredImage?.node?.localFile?.publicURL} )`}}></div>
                                </a>
                                </figure>
                            }
                            <div className={pressDetails}>

                                <h3 className={`mb-0 ${index === 0 ? 'xl:text-4xl' : 'xl:text-inherit'}`}>
                                    <a href={press.node.pressSettings.pressUrl} target={`_blank`} rel={`noopener noreferrer`} title={pressItemTitle}> 
                                {pressItemTitle}
                                    </a>
                                </h3>


                                {
                                    pkMetaInfo.length > 0 &&

                                    <div className={`text-xs font-bold pt-3`}>
                                        {
                                            pkMetaInfo.map((pk, index) => {
                                                return (<Fragment key={index}>
                                                    { index ? <span className={`px-1`}>/</span> : '' }
                                                    <span className={`text-accent`}> {pk} </span>
                                                    </Fragment>)
                                            })
                                        }

                            {press.node.pressSettings.pressAuthor&&
                                <span className={`block font-medium pt-1`}><span>By </span>{press.node.pressSettings.pressAuthor}</span>
                                }

                                    </div>
                                }
                            </div>
                        </div>
                    )
                })}

            </div>

        </div>
    )
}

export default Press
