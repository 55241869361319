import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ItemSlider from "./ItemSlider"

export default function Charts(props) {
    const data = useStaticQuery(graphql`
    query ChartQuery {
        allWpChart(filter: {status: {eq: "publish"}}) {
            edges {
              node {
                slug
                id
                title
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    filename
                  }
                }
              }
            }
    }

}
    `)

    const { sliderClassName } = props;

    let sliderBaseClassName = null;

    if (sliderClassName) sliderBaseClassName = sliderClassName;

    return (
        <div>
            <ItemSlider slidesToShow={4} includeTitle={true} includeLink={`chart`} className={sliderBaseClassName} sliderData={data.allWpChart.edges} pageTitle={props.pageTitle ? props.pageTitle : null} sectionTitle={props.sectionTitle ? props.sectionTitle : null} />
        </div>
    )
}