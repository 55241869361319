import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { coverMedia } from '../styles/media.module.scss'
// import { useParallax } from 'react-scroll-parallax';

export default function CoverMedia(props) {

    
        const { mediaData, className } = props;

        // const parallax = useParallax({
        //   speed: -7.5,
        // });

        if (!mediaData) return null

        const imageData = getImage(mediaData?.node?.localFile)

        let coverClassName = `w-full relative min-h-screen`;
        if (className) coverClassName = [coverClassName, className].join(" ");

        

    return (


        <figure className={coverClassName}>

            <div className={`absolute top-0 right-0 left-0 bottom-0 z-10 h-full w-full ${coverMedia}`}>
            <GatsbyImage 
                        image={imageData}
                        alt={mediaData.altText || `Milo Walker Johnson`}
                        className={`h-full w-full`}
                        imgClassName={`sm:object-center object-[70%]`}
                        
                    />
            

            </div>
            {props.children &&
            
              <div className={`absolute z-50 ${props.innerClassName ? props.innerClassName : 'top-0 right-0 left-0 bottom-0 inline-block'}`}>
                {props.children}
              </div>
            }

        </figure>
    )
}