import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { introContent } from '../styles/snippet.module.scss'

export default function Introduction(props) {
    const data = useStaticQuery(graphql`
    query IntroTextQuery {
        wpContentSnippet(id: {eq: "cG9zdDoxMjc="}) {
            id
            slug
            title
            content
            contentSettings {
                showTitle
                publicTitle
                contentAlign
            }
          }
        }
  `)

  const { showTitle, publicTitle, contentAlign } = data.wpContentSnippet.contentSettings;

  let snippetTitle = data.wpContentSnippet.title;
  if (publicTitle || publicTitle !== '')  snippetTitle = publicTitle;

  let introClassName = `${props.className} text-${contentAlign}`.trim();

  return (

    <div className={introClassName}>
        {showTitle &&

            <h2>{snippetTitle}</h2>
        }

        {data.wpContentSnippet.content &&
        
        
        <div className={introContent} dangerouslySetInnerHTML={{__html: data.wpContentSnippet.content}} />

        
        }


    </div>
  )


}

Introduction.defaultProps = {
    className: ''
}