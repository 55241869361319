import React, { useState, useEffect } from 'react'
import randomizeArray from '../utils/randomizeArray'
import galleryImages from '../data/gallery-images'
import * as styles from "../styles/picgrid.module.scss"

const PicGrid = props => {

    const { number, contained = true, ...rest } = props;

    let numberClass = styles.picGridFour;
    if ( number === 5 ) numberClass = styles.picGridFive;
    else if ( number >= 6 ) numberClass = styles.picGridSix;

    let mainClassName = [styles.picGrid, numberClass].join(" ");

    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(randomizeArray(galleryImages, number));
    }, [number])

    if (isNaN(number) ) return null;

    return (
        <div {...rest}>
            <div className={`${mainClassName} ${contained === false ? styles.picGridFull : ''}`}>
                <div className={`grid grid-flow-col grid-rows-5 ${styles.gridWrapper}`}>
                        {
                            items
                            .map(pic => (
                                <div className={`m-0 p-0`} key={pic.id}>
                                    {pic.image}
                                </div>
                            ))
                        }
                </div>
            </div>
            {props.children &&
                props.children
            }
        </div>
    )
}

PicGrid.defaultProps = {
    number: 4
}

export default PicGrid;