import React from 'react';
import { Link } from 'gatsby';
import { button, buttonOutline, buttonSecondary, backButton } from "../styles/button.module.scss";


export default function Button(props) {

    const { as: Component, variation = "primary", type, className, children, ...rest } = props;

    let target = null;
    let rel = null;

    if (type === "external") {
        target = `_blank`;
        rel = `noopener noreferrer`;
    }

    let buttonClassName = button;

    if (variation === "outline") buttonClassName = [button, buttonOutline].join(" ");
    if (variation === "secondary") buttonClassName = [button, buttonSecondary].join(" ");

    if (className) buttonClassName = [buttonClassName, className].join(" ");

    return (
        <Component className={buttonClassName} target={target} rel={rel} {...rest}>
            {children}
        </Component>
    )
}

Button.defaultProps = {
    as: Link
}

export const BackButton = props => {

    const { variation = "primary", className, children, ...rest } = props;

    let backButtonClassName = backButton;

    if (className) backButtonClassName = [backButton, className].join(" ");

    return (

        <Button variation={variation} as={"button"} className={backButtonClassName} {...rest}>
            <span></span>
            {children}
        </Button>
    )
}


export const DownloadButton = props => {

    const { fileUrl, filename, children } = props;

    // const downloadFile = () => {
    //   window.location.href = fileUrl;
    // }

    let downloadFilename = "File";
    if (filename) downloadFilename = filename;

    if (!fileUrl) return null;

    return (
              <a className={button} href={fileUrl} download={downloadFilename} target={`_blank`}>{children}</a>
    )
}