import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// import Button from "./Button"
import { PageTitle, SectionTitle } from "./Titles";
import { itemSlider, itemSlide, itemMedia, previous, next } from '../styles/slider.module.scss'

export default function ItemSlider(props) {

    const { sliderData, pageTitle, sectionTitle, className } = props;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: props.slidesToShow,
        slidesToScroll: 1,
        autoplay: props.autoplay,
        autoplaySpeed: props.autoplaySpeed,
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      };

    let sliderWrapperClassName = 'overflow-x-hidden';

    if (className) sliderWrapperClassName =    `${className} overflow-x-hidden`;




    return (
      <div className={sliderWrapperClassName}>
        {
              pageTitle &&

              <PageTitle
              className={`flex-auto`}
              as={`h2`}
              >
                  { pageTitle }
              
              </PageTitle>
          }

            {
              sectionTitle &&

              <SectionTitle
              className={`flex-auto`}
              as={`h3`}
              >
                  { sectionTitle }
            </SectionTitle>
          }
        <Slider {...settings} className={`${itemSlider} item-slider`}>
          {sliderData.map(slide => {

            const itemImageData = getImage(slide.node.featuredImage?.node?.localFile)
              
            return (
                <div key={slide.node.id} className={itemSlide}>

                        {slide.node.featuredImage &&
                            <div className={`w-full h-full relative border-2 border-prose mb-3 ${itemMedia}`}>

                            <GatsbyImage 
                            image={getImage(itemImageData)} alt={slide.node.featuredImage.altText || slide.node.title} 
                            className={`w-full h-full border border-color-text`}
                            imgClassName={`w-full h-full`}
                            />
                              {props.includeLink &&
                                <Link className={`z-10 absolute top-0 right-0 left-0 bottom-0 h-full w-full block`} to={`${props.includeLink}/${slide.node.slug}`}></Link>
                              }

                            </div>

                           
                        }

                        {props.includeTitle === true &&
                        <h4 className={`text-center text-base uppercase mt-4 font-primary`}>
                          
                          

                          {props.includeLink ? (
                            <Link to={`${props.includeLink}/${slide.node.slug}`}>{slide.node.title}</Link>
                          ) : (
                            slide.node.title
                          )
                          
                          }
                          
                          </h4>
                        }
                </div>
              )
          })}
        </Slider>
      </div>
    )
}

ItemSlider.defaultProps = {
    slidesToShow: 3,
    pageTitle: null,
    sectionTitle: null,
    autoplay: false,
    autoplaySpeed: null
}


export const PreviousArrow = (props) => {
    return (
        <button type="button" className={`${previous} before:hidden`} onClick={props.onClick}>
            <span className={`arrow-prev`}></span>
        </button>
    )
}

export const NextArrow = (props) => {
    return (
        <button type="button" className={`${next} before:hidden`} onClick={props.onClick}>
            <span className={`arrow-next`}></span>
        </button>
    )
}
