import React from 'react'
import Container from './Container';
import { PageTitle, SectionTitle } from "./Titles";
import { section } from '../styles/section.module.scss'

export default function Section(props) {
    const { pageTitle, pageTitleSize, sectionTitle, containerSize, children, ...rest } = props;

    let sizeOfContainer = null;
    let sectionClassName = section;

    if (props.className) sectionClassName = [section, props.className].join(" ");

    if (containerSize) sizeOfContainer = containerSize;

    let sectionId = null;
    if (props.id) sectionId = props.id;

    if (!children) return null;

    let pTitleSize = null;
    if (pageTitleSize) pTitleSize = pageTitleSize;

    return (

        <div className={sectionClassName} id={sectionId}>
            <Container size={sizeOfContainer}>
            <div {...rest}>
            {
              pageTitle &&

              <PageTitle as={`h2`} size={pTitleSize}
              >
                  { pageTitle }
              
              </PageTitle>
          }

            {
              sectionTitle &&

              <SectionTitle as={`h3`}
              >
                  { sectionTitle }
            </SectionTitle>
          }
                {children}
            </div>
            </Container>
        </div>
    )
}